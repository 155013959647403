import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-1b720dc0"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "task"
};
var _hoisted_2 = {
  class: "mt-header"
};
var _hoisted_3 = {
  class: "tab"
};
var _hoisted_4 = ["onClick"];
var _hoisted_5 = {
  class: "right"
};
var _hoisted_6 = {
  key: 0,
  class: "complete"
};
var _hoisted_7 = {
  key: 0
};
var _hoisted_8 = ["onClick"];
var _hoisted_9 = {
  key: 1
};
var _hoisted_10 = ["onClick"];
var _hoisted_11 = {
  key: 1,
  class: "complete"
};
var _hoisted_12 = {
  key: 0
};
var _hoisted_13 = {
  key: 1
};
import { ref } from 'vue';
import { myTask as myTaskApi, getAward as getAwardApi } from '@/api/me';
export default {
  setup: function setup(__props) {
    var currentType = ref(1);
    var pageData = ref([]);

    var getTaskAward = function getTaskAward(i) {
      var params = {
        id: i.get_ids
      };
      getAwardApi(params).then(function () {
        i.get_ids = 0;
      }).catch();
    };

    var handleChangeType = function handleChangeType(v) {
      currentType.value = v;
      getMyTask(v);
    };

    var getMyTask = function getMyTask(v) {
      var params = {
        type: v
      };
      myTaskApi(params).then(function (res) {
        pageData.value = res;
      }).catch();
    };

    getMyTask(currentType.value);
    var menuList = ref([{
      id: 1,
      name: '新手任务'
    }, {
      id: 2,
      name: '每日任务'
    }, {
      id: 3,
      name: '全局任务'
    }]);
    return function (_ctx, _cache) {
      var _component_Header = _resolveComponent("Header");

      var _component_coin = _resolveComponent("coin");

      var _component_el_icon = _resolveComponent("el-icon");

      var _component_money = _resolveComponent("money");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_Header, {
        title: "任务中心",
        name: "-1"
      }), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menuList.value, function (item) {
        return _openBlock(), _createElementBlock("div", {
          key: item.id,
          class: _normalizeClass({
            active: item.id === currentType.value
          }),
          onClick: function onClick($event) {
            return handleChangeType(item.id);
          }
        }, _toDisplayString(item.name), 11, _hoisted_4);
      }), 128))]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(pageData.value, function (item) {
        return _openBlock(), _createElementBlock("div", {
          class: "list",
          key: item.id
        }, [_createTextVNode(_toDisplayString(item.title) + " ", 1), _createElementVNode("div", _hoisted_5, [item.complete === 1 && item.get_ids > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_6, [Number(item.credits) > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createElementVNode("span", {
          onClick: function onClick($event) {
            return getTaskAward(item);
          },
          class: "active"
        }, "领取+" + _toDisplayString(item.credits) + "积分", 9, _hoisted_8)])) : Number(item.coins) > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_createElementVNode("span", {
          onClick: function onClick($event) {
            return getTaskAward(item);
          },
          class: "active"
        }, "领取+" + _toDisplayString(item.coins) + "超级币", 9, _hoisted_10)])) : _createCommentVNode("", true)])) : (_openBlock(), _createElementBlock("div", _hoisted_11, [Number(item.credits) > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_12, [_createElementVNode("span", {
          class: _normalizeClass({
            gray: item.complete === 1
          })
        }, [_createVNode(_component_el_icon, null, {
          default: _withCtx(function () {
            return [_createVNode(_component_coin)];
          }),
          _: 1
        }), _createTextVNode(" +" + _toDisplayString(item.credits) + "积分", 1)], 2)])) : Number(item.coins) > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_13, [_createElementVNode("span", {
          class: _normalizeClass({
            gray: item.complete === 1
          })
        }, [_createVNode(_component_el_icon, null, {
          default: _withCtx(function () {
            return [_createVNode(_component_money)];
          }),
          _: 1
        }), _createTextVNode(" +" + _toDisplayString(item.coins) + "超级币", 1)], 2)])) : _createCommentVNode("", true)]))])]);
      }), 128))])]);
    };
  }
};